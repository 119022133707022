
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmEmoji from '@/components/shared/TmEmoji.vue'
import type { PropType } from 'vue'
import type { EmojiNameType } from '@/definitions/shared/types'

export default defineComponent({
  name: 'InfoCardRow',
  components: {
    TmEmoji,
    TmButton,
  },
  props: {
    emoji: {
      type: String as PropType<EmojiNameType>,
    },
    title: {
      type: String,
    },
    withoutBorder: {
      type: Boolean,
    },
    alignTop: {
      type: Boolean,
    },
    text: {
      type: String,
    },
    link: {
      type: Object,
    },
    disable: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
    largeButtons: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const hasImageSlot = !!context.slots.image
    const hasBtnSlot = !!context.slots.btn
    const hasTitleSlot = !!context.slots.title
    const hasTextSlot = !!context.slots.text

    return {
      hasImageSlot,
      hasBtnSlot,
      hasTitleSlot,
      hasTextSlot,
    }
  },
})
