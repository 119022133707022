
import { defineComponent, reactive, watch, onMounted, onBeforeUnmount } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCards from '@/components/shared/templates/InfoCards.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import { useBottomBar } from '@/compositions/bottomBar'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import { editorBtns } from '@/definitions/shared/editor/data'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTabsNav from '@/components/shared/TmTabsNav.vue'

export default defineComponent({
  components: {
    TmTabsNav,
    TmButton,
    InfoCardRow,
    PageContent,
    DetailsHero,
    InfoCards,
    InfoCard,
  },
  setup() {
    const { provideData, getDiscardFunc } = useBottomBar()
    const breadcrumbs = [
      { label: 'Tickets', url: { name: 'base.tickets' } },
      { label: 'Settings', url: { name: 'base.tickets.settings' } },
      { label: 'General', url: '' },
    ]

    const closeDaysOptions = [
      '1 day',
      '3 days',
      '7 days',
      '14 days',
      '30 days',
      '90 days',
    ]

    const state = reactive({
      closeDays: closeDaysOptions[2],
      enabledSignature: true,
      signatureRequired: false,
      startTicketId: '10000000000',
      message: 'All the best,\n{user.name}\n{user.role}\n{user.email}\n{Workspace.phone.number}\n{Workspace.address}\n{Workspace.logo}',
    })

    const defaultState = Object.assign({}, state)

    const btnsList: EditorButtonType[] = [
      editorBtns.text,
      editorBtns.emoji,
      editorBtns.image,
      editorBtns.tags,
    ]

    watch(() => state, (newValue) => {
      JSON.stringify(newValue) !== JSON.stringify(defaultState) ? provideData(1) : getDiscardFunc.value()
    }, { deep: true })

    const restoreEnabled = () => {
      Object.assign(state, defaultState)
    }

    const tabs = [
      {
        name: 'content',
        label: 'Content',
      },
      {
        name: 'html',
        label: 'HTML',
      },
      {
        name: 'preview',
        label: 'Preview',
      },
    ]

    onMounted(() => {
      provideData(0, restoreEnabled)
    })

    onBeforeUnmount(() => {
      getDiscardFunc.value()
    })

    return {
      closeDaysOptions,
      breadcrumbs,
      state,
      tabs,
      btnsList,
    }
  },
})
