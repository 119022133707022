import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-97a7a534"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card-row__image"
}
const _hoisted_2 = {
  key: 1,
  class: "card-row__text"
}
const _hoisted_3 = {
  key: 0,
  class: "card-row__button"
}
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_emoji = _resolveComponent("tm-emoji")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.link ? 'router-link' : 'div'), {
    to: _ctx.link,
    class: _normalizeClass(["card-row", {
      'card-row--link': _ctx.link && !_ctx.$slots.btn,
      'card-row--without-border': _ctx.withoutBorder,
      'card-row--align-top': _ctx.alignTop,
      'card-row--disable': _ctx.disable,
    }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["card-row__content", {
        'card-row__content--large-buttons': _ctx.largeButtons,
      }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["overflow-hidden", {
          'd-flex align-start': _ctx.hasImageSlot || _ctx.emoji || _ctx.icon,
        }])
        }, [
          (_ctx.hasImageSlot || _ctx.emoji || _ctx.icon)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "image", {}, () => [
                  (_ctx.emoji)
                    ? (_openBlock(), _createBlock(_component_tm_emoji, {
                        key: 0,
                        name: _ctx.emoji,
                        size: "xxLarge"
                      }, null, 8, ["name"]))
                    : _createCommentVNode("", true),
                  (_ctx.icon)
                    ? (_openBlock(), _createBlock(_component_tm_icon, {
                        key: 1,
                        name: _ctx.icon,
                        size: "xxLarge",
                        class: "neutral--text"
                      }, null, 8, ["name"]))
                    : _createCommentVNode("", true)
                ], true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.hasTitleSlot || _ctx.hasTextSlot || _ctx.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: _normalizeClass([{ 'light--text': _ctx.disable }, "subhead-semi-bold-16 mb-1"])
                }, [
                  _renderSlot(_ctx.$slots, "title", {}, () => [
                    _createTextVNode(_toDisplayString(_ctx.title), 1)
                  ], true)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["body-text-regular-14 lh-20", _ctx.disable ? 'light--text' : 'neutral--text'])
                }, [
                  _renderSlot(_ctx.$slots, "text", {}, () => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.text), 1)
                  ], true)
                ], 2)
              ]))
            : _createCommentVNode("", true)
        ], 2),
        (_ctx.hasBtnSlot || _ctx.link)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "btn", {}, () => [
                (_ctx.link)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_tm_button, {
                        flat: "",
                        "icon-only": "",
                        disable: _ctx.disable,
                        color: "transparent"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_tm_icon, {
                            class: _normalizeClass(_ctx.disable ? 'light--text' : 'neutral--text'),
                            size: "xxLarge",
                            name: "chevron_right"
                          }, null, 8, ["class"])
                        ]),
                        _: 1
                      }, 8, ["disable"])
                    ]))
                  : _createCommentVNode("", true)
              ], true)
            ]))
          : _createCommentVNode("", true)
      ], 2),
      _renderSlot(_ctx.$slots, "after", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["to", "class"]))
}